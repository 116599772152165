import { CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CTableBody, CTableDataCell, CTableRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import hallApi from '../../../api/hallApi'
import HistoryCircle from './historyCircle'
import dot from '../css/Dot.module.css'

function History({hallId}) {
    const [results, setResults] = useState([])
    
    useEffect(()=>{
        const fetchResult = async () => {
            try {
                const data = await hallApi.results(hallId)
                setResults(data)
            } catch (error) {
                
            }
        }
        fetchResult()
    }, [hallId])
    const rows = 5;
    const cols = 10;
    const resultFormated = Array.from({ length: cols }, () => Array(rows).fill(0));

    if (results.length > 0) {
        let currentRow = 0;
        let currentCol = cols - 1;
        let previousResult = null;

        for (let m = results.length - 1; m >= 0; m--) {
            if (currentCol < 0) break;
            
            if (results[m] === previousResult) {
                if (currentRow < rows) {
                    resultFormated[currentCol][currentRow] = results[m];
                    currentRow++;
                }
            } else {
                if (currentRow > 0 || currentCol === cols - 1) {
                    currentCol--;
                    if (currentCol < 0) break;
                    currentRow = 0;
                }
                resultFormated[currentCol][currentRow] = results[m];
                currentRow++;
            }
            previousResult = results[m];
        }
    }

    const finalResult = Array.from({ length: rows }, (_, rowIndex) =>
        resultFormated.map(col => col[rowIndex] || 0)
    );

    const count = results.reduce((acc, result) => {
        acc[result] = (acc[result] || 0) + 1;
        return acc;
    }, {});

    return (
        <CCard style={{ borderRadius: 0 }}>
            
            <CCardBody>
                <CRow>
                    <CCol sm={1} style={{width: '10%'}}>
                    <span className='me-4'>
                    <span className={`${dot.dot} ${dot.meron} mb-2 mt-4`}>{count['meron'] || 0}</span>
                </span>
                <span className='me-4'>
                    <span className={`${dot.dot} ${dot.wala} mb-2`}>{count['wala'] || 0}</span>
                </span>
                <span className='me-4'>
                    <span className={`${dot.dot} ${dot.draw} mb-2`}>{count['draw'] || 0}</span>
                </span>
                <span className='me-4'>
                    <span className={`${dot.dot} ${dot.cancel} mb-2`}>{count['cancel'] || 0}</span>
                </span>
                    </CCol>
                    <CCol sm={11} style={{width:'90%'}}>
                    <CTable bordered responsive align='middle' className='mb-0'>
                    <CTableBody>
                        {finalResult.map((row, rowIndex) => (
                            <CTableRow key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                    <CTableDataCell
                                        key={cellIndex}
                                        className='historyCircle'
                                        
                                    >
                                        {cell !== 0 ? <HistoryCircle type={cell+'-sum'} /> : <HistoryCircle type={'sum'} />}
                                    </CTableDataCell>
                                ))}
                            </CTableRow>
                        ))}
                    </CTableBody>
                </CTable>
                    </CCol>
                </CRow>
                
            </CCardBody>
        </CCard>
    );
}

export default History;