import AuthFeature from "./features/Auth"
import HomeFeature from "./features/HomeFeature"
import PermissionFeature from "./features/Permission"
import UserFeature from "./features/User"

export const mainRoutes = [
    {path: '/*', Component: HomeFeature},
    {path: '/auth/*', Component: AuthFeature},
    {path: '/user/*', Component: UserFeature},
    {path: '/permissions/*', Component: PermissionFeature},
]