import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useCurrentHall } from '../../../store'
import hallApi from '../api/hallApi'
import SingleHall from '../components/Hall/SingleHall'

function HallIndex() {
    const params = useParams()
    const type=params.type?params.type:'cricket'

    const {currentHall, setCurrentHall} = useCurrentHall()
    const getHalls = async (type) => {
        try {
            const data = await hallApi.get({type})
            setCurrentHall(data[0])
            return data
        } catch (error) {
            
        }
    }
    useEffect(()=>{
        getHalls(type)
    }, [type])
    return (
        <SingleHall hall={currentHall} />
    )
}

export default HallIndex
