import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import authApi from "../api/authApi";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')): null);
  const navigate = useNavigate();
  
  const login = async (data, callback) => {
    try {
      let {user, permissions} = await authApi.login(data);
      auth(user, permissions)
      callback()
    } catch (error) {
        // Swal.fire
    }
  };
  const loginWithToken = async (token, callback) => {
    try {
      let {user, permissions} = await authApi.loginWithToken(token)
      auth(user, permissions)
      callback()
    } catch (error) {
      
    }
  }
  const auth = (user, permissions) => {
    setUser(user);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('permissions', JSON.stringify(permissions))
  }
  const logout = async () => {
    setUser(null);    
    localStorage.clear()
    try {
        await authApi.logout();
    } catch (error) {
        
    } 
    window.location.replace('/');
  };
  
  return (
    <AuthContext.Provider value={{ user, login, logout,loginWithToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
