import React from 'react';
import { useParams } from 'react-router-dom';

const LoginToken = () => {
  const { token } = useParams();

  return (
    <div>
      <h1>Login with Token</h1>
      <p>Your token is: {token}</p>
    </div>
  );
};

export default LoginToken;
