import { cilChatBubble, cilSend } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CFormInput, CInputGroup } from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import useChat from "../../../../../hooks/useChat";
import { useAuth } from "../../../../Auth/context/AuthContext";
import "../css/Chatbox.css"; // File CSS để tạo hiệu ứng

const DevTools = () => {
  const [isOpen, setIsOpen] = useState(false);
    const chatboxRef = useRef(null);
    const { messages, sendMessage } = useChat();
    const [newMessage, setNewMessage] = useState('');
    const {user} = useAuth()
  // Hàm toggle mở hoặc đóng khung DevTools
  const toggleDevTools = () => {
    setIsOpen(!isOpen);
  };
  const handleSendMessage = () => {
    if (newMessage.trim() !== '' && user) {
        console.log(newMessage);
      sendMessage({user: user.name, message: newMessage}); // Gửi tin nhắn
      setNewMessage(''); // Xóa nội dung sau khi gửi
    }
  };
  useEffect(() => {
    if (chatboxRef.current) {
        chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }
  }, [isOpen, messages]);
  return (
    <>
      {/* Bong bóng góc màn hình */}
      {(
        <div className="devtools-bubble" onClick={toggleDevTools}>
          <CIcon icon={cilChatBubble} />
        </div>
      )}

      {/* Khung nội dung nổi lên từ dưới */}
      <div className={`devtools-panel ${isOpen ? "open" : ""}`} ref={chatboxRef}>
        {/* <div className="devtools-header">
          <h6 style={{ margin: "auto" }}>Chatbox </h6>
          <CButton size="sm" color="primary" onClick={toggleDevTools}>
            <CIcon icon={cilX} />
          </CButton>
        </div> */}
        <div className="devtools-content">
            {messages.slice(-100).map((message, index) => {
                return <p key={index} className="chat-message"> 
                <b>{message.user}:</b> {message.message}
              </p>
            })}
          
        </div>
        {user && (<div className="chat-input">
          <CInputGroup>
            <CFormInput
              placeholder="Your message..."
              aria-label="Your message..."
              value={newMessage}
              onChange={(e) => {console.log(e.target.value);setNewMessage(e.target.value)}}
              onKeyDown={(e) => {if(e.key === 'Enter') handleSendMessage()}}
            />
            {newMessage && (<CButton
              type="button"
              color="dark"
              variant="outline"
              style={{ borderRadius: 0, border: "1px solid #e2e9f7" }}
                onClick={()=>handleSendMessage()}
            >
              <CIcon icon={cilSend} />
            </CButton>)}
            
          </CInputGroup>
        </div>)}
        
      </div>
    </>
  );
};

export default DevTools;
