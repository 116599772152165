import axios from "axios";
import Swal from "sweetalert2";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL+ process.env.REACT_APP_BASE_PATH,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: true,
  withXSRFToken: true,
});
axiosClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log('...........ERR..............');
    console.log(error)
    console.log(error.response.data)
    console.log(error.response.data.message)
    if (error.response.data && error.response.data.message) {
      console.log('...........MESSAGE..............');
      console.log(error.response.data.message);
      console.log('.......ENDERR...............')
      switch (error.response.data.message) {
        case "Unauthenticated.":
          delete axiosClient.defaults.headers.common['X-XSRF-TOKEN'];
          localStorage.clear();
          Swal.fire({
            text: "Vui lòng đăng nhập để tiếp tục",
            confirmButtonText: "Đăng nhập",
            backdrop: false,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.replace("/");
            }
          });
          break;

        default:
          Swal.fire({
            text: error.response.data.message,
          });
          break;
      }
    }

    return Promise.reject(error);
  }
);

const checkLoginStatus = async () => {
  try {
    const response = await axiosClient.get('/check-login-status');
    if (response.authenticated) {
      console.log('User is still logged in');
      return true;
    } else {
      localStorage.clear();
      return false;
    }
  } catch (error) {
    console.error('There was an error checking the login status:', error);
    localStorage.clear();
    return false;
  }
};

checkLoginStatus().then(isAuthenticated => {
  if (!isAuthenticated) {
    console.log('User is not logged in');
  }
});
export default axiosClient;
