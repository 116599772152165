import axiosClient from "../../../api/axiosClient"

const hallApi = {
    get(params){
        return axiosClient.get('/hall', {
            params: params
        })
    },
    detail(id){
        if(id)
        return axiosClient.get('/hall/'+id)
    },
    results(id){
        return axiosClient.get('hall/results/'+id)
    },
    getTodayReport(){
        return axiosClient.get('hall/todayReport')
    },
    getBettingData(fightId){
        return axiosClient.get('hall/getbettingdata/'+fightId)
    },
    lastFight(hallId){
        return axiosClient.get('hall/lastFight/'+hallId)
    }
}

export default hallApi