import { useEffect } from 'react';

import { createSocketConnection } from '../services/socketService';
function listen(channel, event, callback) {
  window.Echo.channel(channel).listen(event, (payload) => {
    callback(payload);
  });

  return function cleanUp() {
    window.Echo.leaveChannel(`private-${channel}`);
  };
}


export const useSocket = ({ type, callBack }) => {
  useEffect(() => {
    createSocketConnection();
    const channel = 'game-state'
    switch (type) {
        case 'RATE_CHANGE':
            listen(channel,'.rate-change', callBack);
            break
        case 'STATUS_CHANGE':
            listen(channel, '.status-change', callBack);
            break
      default:
        break
    }
  }, [callBack,type]);
};