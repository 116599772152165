import { useState, useEffect } from 'react';
import { ref, onValue, set, push } from 'firebase/database';
import { database } from './firebase'; // Đường dẫn đến file cấu hình Firebase của bạn

// Custom hook để lắng nghe và gửi tin nhắn
const useChat = () => {
  const [messages, setMessages] = useState([]);

  // Lắng nghe dữ liệu tin nhắn theo thời gian thực
  useEffect(() => {
    const messagesRef = ref(database, `chats/messages`);

    const unsubscribe = onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      const messageList = data ? Object.values(data) : [];
      setMessages(messageList);
    });

    return () => unsubscribe(); // Cleanup khi component unmount
  }, []);

  // Hàm để gửi tin nhắn
  const sendMessage = (message) => {
    const messagesRef = ref(database, `chats/messages`);
    const newMessageRef = push(messagesRef); // Tạo một ref mới cho tin nhắn
    set(newMessageRef, {
      ...message,
      timestamp: new Date().getTime()
    });
  };

  return { messages, sendMessage };
};

export default useChat;