import React from 'react'
import useProfile from '../hooks/useProfile'

function UserProfile() {
    const { data, isLoading, error } = useProfile();
    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>An error has occurred: {error.message}</div>;
    }
    return (
        <div>
            {JSON.stringify(data)}
        </div>
    )
}

export default UserProfile
