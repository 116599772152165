import { CBadge, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import React, { useEffect, useState } from 'react'
import hallApi from '../../../api/hallApi';

function CurrentBet({fightId, fightNumber}) {
    const [betting, setBetting] = useState([])
    const badgeColor = {
        wala: "primary",
        meron: "danger",
        draw: "success",
      };
      const handleChange = () => {
        const userBetting = localStorage.getItem('betting-'+fightId)?JSON.parse(localStorage.getItem('betting-'+fightId)):[];
        setBetting(userBetting)
      }
      useEffect(() => {
        const fetchData = async () => {
          const storedBetting = localStorage.getItem(`betting-${fightId}`);
          if (!storedBetting) {
            try {
              console.log('vao day nua ko');
              const data = await hallApi.getBettingData(fightId);
              localStorage.setItem(`betting-${fightId}`, JSON.stringify(data));
              setBetting(data);
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          } else {
            setBetting(JSON.parse(storedBetting));
          }
        };
    
        fetchData();
    
        window.addEventListener('bettingChange', handleChange);
        return () => {
          window.removeEventListener('bettingChange', handleChange);
        };
      }, [fightId]);
    return (
        <CTable>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell>#Fight</CTableHeaderCell>
                <CTableHeaderCell>BET</CTableHeaderCell>
                <CTableHeaderCell>$</CTableHeaderCell>
                <CTableHeaderCell>Rate</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {betting?betting.map((value, key) => {
                return (
                  <CTableRow key={key}>
                    <CTableDataCell>{fightNumber}</CTableDataCell>
                    <CTableDataCell>
                      <CBadge
                        color={badgeColor[value.party]}
                        style={{ textTransform: "uppercase" }}
                      >
                        {value.party}
                      </CBadge>
                    </CTableDataCell>
                    <CTableDataCell>{value.amount}</CTableDataCell>
                    <CTableDataCell>{value.rate}</CTableDataCell>
                  </CTableRow>
                );
              }):''}
            </CTableBody>
          </CTable>
    )
}

export default CurrentBet
