import { CCol, CContainer, CRow } from '@coreui/react'
import React from 'react'
import SingleHall from '../components/Hall/SingleHall'

function HomeIndex() {
    return (
        <SingleHall />
    )
}

export default HomeIndex
