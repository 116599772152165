import { cilLockLocked, cilUser } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CCol, CForm, CFormInput, CInputGroup, CInputGroupText, CNavLink, CRow } from '@coreui/react'
import React from 'react'
import { useAuth } from '../context/AuthContext'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import * as Yup from "yup";
function LoginForm({callback}) {
    const auth = useAuth()
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      id: "",
      password: "",
    },
    resolver: validator,
  });
  const login = (data) => {
    auth.login(data, callback)
    return;
  };
    return (
        <CForm onSubmit={handleSubmit(login)}>
            <CInputGroup className="mb-4">
                <CInputGroupText id="addon-wrapping">
                    <CIcon icon={cilUser} />
                </CInputGroupText>
                <CFormInput
                    placeholder="Username"
                    name="id"
                    {...register("id")}
                />
                <div
                    className="error invalid-feedback"
                    style={{ display: "flex" }}
                >
                    {errors.id?.message}
                </div>
            </CInputGroup>
            <CInputGroup className="mb-4">
                <CInputGroupText id="addon-wrapping">
                    <CIcon icon={cilLockLocked} />
                </CInputGroupText>
                <CFormInput
                    type="password"
                    placeholder="Password"
                    name="password"
                    {...register("password")}
                />
                <div
                    className="error invalid-feedback"
                    style={{ display: "flex" }}
                >
                    {errors.password?.message}
                </div>
            </CInputGroup>
            <CRow>
                <CCol md={4}>
                    <CButton
                        className="px-4"
                        type="submit"
                    >
                        Login
                    </CButton>
                </CCol>
                
                <CCol md={4}>
                    <CButton
                        className="px-4"
                        type="button"
                        color='success'
                    >
                        Register
                    </CButton>
                </CCol>
                <CCol md={4}>
                    <CNavLink
                        className="px-0"
                        href="#"
                    >
                        Forgot password?
                    </CNavLink>
                </CCol>
            </CRow>
        </CForm>
    )
}
const validator = yupResolver(
    Yup.object({
      id: Yup.string().required("Vui lòng nhập ID hoặc Email"),
      password: Yup.string()
        .required("Vui lòng nhập mật khẩu")
        .min(6, "Mật khẩu cần trên 6 ký tự"),
    })
  );
export default LoginForm
