import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import React from 'react'
import PermissionTable from '../components/PermissionTable'
import UnRegistedPermissions from '../components/UnRegistedPermissions'

export default function PermissionIndex() {
  
  return (
    <div>
      <CCard>
            <CCardHeader>Danh sách quyền chưa được cấp</CCardHeader>
            <CCardBody>
              <UnRegistedPermissions/>
            </CCardBody>
          </CCard>
          <CCard className='mt-4'>
          <CCardHeader>Bảng phân quyền</CCardHeader>
          <CCardBody>
            <PermissionTable/>
          </CCardBody>
        </CCard>
    </div>
   
  )
}
