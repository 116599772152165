import { CBadge, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import React, { useEffect, useState } from 'react'
import hallApi from '../../../api/hallApi';

function TodayReport() {
    const [todayReport, setTodayReport] = useState([]);
    useEffect(() => {
        const fetchResult = async () => {
          try {
            const data = await hallApi.getTodayReport();
            setTodayReport(data);
            console.log(data);
          } catch (error) {
            console.error(error);
          }
        };
        fetchResult();
      }, []);
    const badgeColor = {
        wala: "primary",
        meron: "danger",
        draw: "success",
      };
    return (
        <CTable>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell>#Fight</CTableHeaderCell>
                <CTableHeaderCell>BET</CTableHeaderCell>
                <CTableHeaderCell>$</CTableHeaderCell>
                <CTableHeaderCell>Win</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {todayReport
                ? todayReport.map((value, key) => {
                    return (
                      <CTableRow key={key}>
                        <CTableDataCell>{value.fight_id}</CTableDataCell>
                        <CTableDataCell>
                          <CBadge
                            color={badgeColor[value.party]}
                            style={{ textTransform: "uppercase" }}
                          >
                            {value.party}
                          </CBadge>
                        </CTableDataCell>
                        <CTableDataCell>{value.amount}</CTableDataCell>
                        <CTableDataCell>{value.won}</CTableDataCell>
                      </CTableRow>
                    );
                  })
                : ""}
            </CTableBody>
          </CTable>
    )
}

export default TodayReport
