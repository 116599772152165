import { useQuery } from "@tanstack/react-query";
import { groupApi } from "../api/groupApi";

function useGroups(){
    return useQuery({
        queryKey: ['groupList'],
        queryFn: () => groupApi.getAll(),
    })
}

export {useGroups}