import axiosClient from "../../../api/axiosClient";

const authApi = {
    login(credentials) {
        return axiosClient.post('auth/login', credentials);
    },
    logout() {
        return axiosClient.get('auth/logout');
    },
    getUser() {
        return axiosClient.get('user');
    },
    loginWithToken(token) {
        return axiosClient.post('auth/loginUsingToken', {}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
}

export default authApi;