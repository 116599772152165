import { cilLockLocked, cilUser } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CImage,
  CInputGroup,
  CInputGroupText,
  CNavLink,
  CRow,
} from "@coreui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { Link} from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../context/AuthContext";
export default function Login() {
  document.title = "Đăng nhập"
  const auth = useAuth()
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      id: "",
      password: "",
    },
    resolver: validator,
  });
  const login = (data) => {
    auth.login(data)
    return;
  };
  return (
    <></>
  );
}

const validator = yupResolver(
  Yup.object({
    id: Yup.string().required("Vui lòng nhập ID hoặc Email"),
    password: Yup.string()
      .required("Vui lòng nhập mật khẩu")
      .min(6, "Mật khẩu cần trên 6 ký tự"),
  })
);

