import axiosClient from "../../../api/axiosClient";
export const permissionApi = {
  getPermissions(params) {
    return axiosClient.get("permission", { params });
  },
  getUnregisterdPermissions() {
    return axiosClient.get("permission/unregisted");
  },
  createPermissions(payload) {
    return axiosClient.post("permission", payload);
  },
  assignPermission(id,state) {
    return axiosClient.post("permission/assign", {id, state});
  },
};
